'use client'

import clsx from 'clsx'
import Image from 'next/image'
import { memo, useState } from 'react'
import { BlurOverImage } from '../BlurOverImage'

export const BookImage = memo(function Bookimage(props: {
  src: string | null
  alt: string
  disableAnimate?: boolean
  className?: string
  commonClassName?: string
  width?: number
  height?: number
  fill?: boolean
  sizes?: string
  isBlur?: boolean
  isHoverEffect?: boolean
}) {
  const {
    disableAnimate = false,
    isBlur = false,
    isHoverEffect = false,
  } = props
  const [isLoading, setIsLoading] = useState(!disableAnimate)
  const [src, setSrc] = useState(props.src)

  const hoverClassName =
    'after:transition-opacity relative after:absolute group-hover:after:opacity-10 after:opacity-0 after:inset-0 dark:after:bg-black-1000 after:bg-white'

  if (!src) {
    return (
      <div
        className={clsx(props.className, props.commonClassName, {
          [hoverClassName]: isHoverEffect,
        })}
      />
    )
  }

  return (
    <div
      className={clsx(props.className, props.commonClassName, {
        [hoverClassName]: isHoverEffect,
      })}
    >
      <Image
        {...(disableAnimate
          ? { priority: true }
          : { onError: () => setSrc(null), onLoad: () => setIsLoading(false) })}
        src={src}
        fill={props.fill}
        sizes={props.sizes}
        alt={props.alt}
        className={clsx('object-cover overflow-hidden', props.commonClassName, {
          'transition-opacity duration-500': !disableAnimate,
          'opacity-0': isLoading,
          'opacity-100': !isLoading,
        })}
        width={props.width}
        height={props.height}
      />

      {isBlur && <BlurOverImage />}
    </div>
  )
})
